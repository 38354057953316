import React, { useEffect, useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import Footer from "./Layout/Footer";
import Navbar from "./Navbar";
import bgimg from "../assets/images/Frame.jpg";
import copy_icon from "../assets/images/copy.svg";
import refresh_icon from "../assets/images/refresh_icon.svg";
import { toast, ToastContainer } from "react-toastify";
import Ably from "ably";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

const Projects = () => {
  const Spinner = () => {
    return (
      <div className="border-t-4 border-blue-500 border-solid rounded-full w-6 h-6 animate-spin"></div>
    );
  };
  const navigate = useNavigate();
  const location = useLocation();
  const [qrkey, setQrKey] = useState("");
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const deviceFromState = location?.state?.device;

  const randomString = (length) => {
    const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let output = "";
    for (let x = 0; x < length; x++) {
      let i = Math.floor(Math.random() * chars.length);
      output += chars.charAt(i);
    }
    setQrKey(output);
  };

  useEffect(() => {
    if (!qrkey) {
      randomString(8); // Initialize QR code key if empty
    } else {
      const ably = new Ably.Realtime(
        "3NVEtA.J5l1NA:KkJVF7eB7DuZxoueBCHQEHYjKF1CAISdnVlRKZ3Emn8"
      );
      const channel = ably.channels.get("text-sync-channel-1");

      // Subscribe to the channel for "text-update" messages
      console.log(qrkey + "connection");

      channel.subscribe(qrkey + "connection", (message) => {
        console.log("Received message:", message);
        const deviceData = message.data.device;
        if (message.data.msg === "ConnectionRequest") {
          setIsLoading(true);
          channel.publish(qrkey + "connection_accept", { status: true });
          console.log(qrkey + "connection_accept");
          setTimeout(() => {
            navigate("/qrtext", {
              state: { qrkey, device: deviceData,device_type:message.data.type },
            });
          }, 2000);
        }
      });

      // Clean up subscription when the component unmounts
      return () => {
        channel.unsubscribe();
        // ably.close(); // Close Ably connection when component unmounts
      };
    }
  }, [qrkey]);
  const handleCopy = () => {
    navigator.clipboard.writeText(qrkey).then(() => {
      toast.success("Copied to clipboard!"); // Show toast notification
    });
  };

  const handleRefresh = () => {
    setIsRefreshing(true);
    randomString(8); // Generate a new random QR key
    setTimeout(() => setIsRefreshing(false), 1000); // Stop the spin animation after 1 second
  };

  return (
    <div
      className="min-h-screen flex flex-col"
      style={{
        backgroundImage: `url(${bgimg})`,
        backgroundSize: "cover",
      }}
    >
      <ToastContainer />
      {/* Navbar fixed at the top */}
      <Navbar />

      {/* Main Content Area with flex-grow to push footer to the bottom */}
      <div className="flex w-full flex-col justify-center items-center flex-grow">
        <div
          style={{ width: "400px", backgroundColor: "white" }}
          className="p-8 w-full text-center rounded-xl shadow-lg"
        >
          <h2 className="text-2xl font-semibold mb-4">
            Scan QR with Mallany Mic App
          </h2>
          <div className="flex justify-center gap-3 items-center  ">
            <span className="border rounded-md flex gap-4 items-center">
              {" "}
              <p className="text-lg mb-0 ps-4 fw-bold font-mono">{qrkey}</p>
              <span
                onClick={handleCopy}
                className="custom_bgColor cursor-pointer rounded-e-md p-3"
              >
                <img
                  width={20}
                  className="  cursor-pointer"
                  src={copy_icon}
                  alt="copy_icon"
                />
              </span>
            </span>
            <img
              width={30}
              className={`cursor-pointer ${isRefreshing ? "spin" : ""}`}
              src={refresh_icon}
              alt="refresh_icon"
              onClick={handleRefresh}
            />
          </div>

          {/* <div
            style={{ marginTop: "40px" }}
            className="flex mb-2 justify-center"
          >
            <QRCodeCanvas value={qrkey} size={200} />
          </div>
          {isLoading && (
            <div className="mt-4 text-lg">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )} */}
          <div
            style={{ marginTop: "40px" }}
            className="flex mb-2 justify-center"
          >
            <div
              style={{ position: "relative", width: "200px", height: "200px" }}
            >
              {/* QR Code */}
              <QRCodeCanvas value={qrkey} size={200} />

              {/* Overlay */}
              {isLoading && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(255, 255, 255,0.9)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 10,
                    // To match QR Code's rounded corners
                  }}
                >
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Footer fixed at the bottom */}
      <Footer />
    </div>
  );
};

export default Projects;

import Footer from "./Layout/Footer";
import JoditEditor from "jodit-react";
import React, { useEffect, useReducer, useRef, useState } from "react";
import Ably from "ably";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import bgimg from "../assets/images/Frame.jpg";
import Navbar from "./Navbar";
import { BASE_URL } from "../config";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import save_icon from "../../src/assets/svg/Upload_icon.svg";
import { toast, ToastContainer } from "react-toastify";

// Reducer Constants
const CLEAR_TRANSCRIPT = "CLEAR_TRANSCRIPT";
const APPEND_TRANSCRIPT = "APPEND_TRANSCRIPT";

// Utility function to concatenate transcripts
const concatTranscripts = (...transcriptParts) => {
  return transcriptParts
    .map((t) => t.trim())
    .join(" ")
    .trim();
};

// Initial state for the transcript
const initialState = {
  interimTranscript: "",
  finalTranscript: "",
};

// Reducer function to handle transcript updates
const transcriptReducer = (state, action) => {
  switch (action.type) {
    case CLEAR_TRANSCRIPT:
      return {
        interimTranscript: "",
        finalTranscript: "",
      };

    case APPEND_TRANSCRIPT:
      return {
        interimTranscript: action.payload.interimTranscript,
        finalTranscript: concatTranscripts(
          state.finalTranscript,
          action.payload.finalTranscript
        ),
      };

    default:
      throw new Error();
  }
};

// Function to format the transcript text
const formatTranscript = (transcript) => {
  return transcript
    .replace(/comma/gi, ",")
    .replace(/full stop/gi, ".")
    .replace(/question mark/gi, "?")
    .replace(/exclamation mark/gi, "!")
    .replace(/colon/gi, ":")
    .replace(/semicolon/gi, ";")
    .replace(/dash/gi, "-")
    .replace(/open quote/gi, `“`)
    .replace(/close quote/gi, `”`)
    .replace(/open parenthesis/gi, "(")
    .replace(/close parenthesis/gi, ")")
    .replace(/ellipsis/gi, "…")
    .replace(/apostrophe/gi, "'")
    .replace(/at symbol/gi, "@")
    .replace(/hashtag/gi, "#")
    .replace(/dollar sign/gi, "$")
    .replace(/percent sign/gi, "%")
    .replace(/ampersand/gi, "&")
    .replace(/asterisk/gi, "*")
    .replace(/forward slash/gi, "/")
    .replace(/backslash/gi, "\\")
    .replace(/greater than/gi, ">")
    .replace(/less than/gi, "<")
    .replace(/equals sign/gi, "=")
    .replace(/plus sign/gi, "+")
    .replace(/minus sign/gi, "-")
    .replace(/underscore/gi, "_")
    .replace(/caret/gi, "^")
    .replace(/vertical bar/gi, "|");
};

const QrToText = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { qrkey, device,device_type } = location.state || {};
  const [state, dispatch] = useReducer(transcriptReducer, initialState);
  const [description, setDescription] = useState("");
  const [name, setProjectName] = useState("");
  const [language, setLanguage] = useState("en");
  const [isModalOpen, setModalOpen] = useState(false); // Modal visibility

  const { finalTranscript } = state;
  const { id } = useParams();

  const lastMessageRef = useRef("");

  useEffect(() => {
    // Initialize Ably
    const ably = new Ably.Realtime(
      "3NVEtA.J5l1NA:KkJVF7eB7DuZxoueBCHQEHYjKF1CAISdnVlRKZ3Emn8"
    );
    const channel = ably.channels.get("text-sync-channel");

    // Subscribe to the channel for "qrkey" updates
    channel.subscribe(qrkey, (message) => {
      console.log(message) 
      console.log(device_type) 
      const newMessage = message.data.text.trim();
      console.log("newMessage", newMessage);

      if(device_type == "android"){
        const currentText = finalTranscript.trim();

        // Find the longest suffix of `currentText` that matches a prefix of `newMessage`
        let overlapIndex = 0;
        for (let i = 1; i <= newMessage.length; i++) {
          if (currentText.endsWith(newMessage.slice(0, i))) {
            overlapIndex = i;
          }
        }
  
        // Append only the non-overlapping part of `newMessage` to `text`
        const uniquePart = newMessage.slice(overlapIndex);
        if (uniquePart) {
          console.log("uniquePart", uniquePart);
  
          // Format the unique part of the transcript
          const formattedUniquePart = formatTranscript(uniquePart);
  
          // Dispatch the formatted message to update the transcript
          dispatch({
            type: APPEND_TRANSCRIPT,
            payload: {
              interimTranscript: formattedUniquePart,
              finalTranscript: formattedUniquePart,
            },
          });
  
          lastMessageRef.current = newMessage; // Update last message reference
        }
      }else{
        setValue(newMessage);
        console.log("fhksdjfhdkjsfhskdjhf")
      }
      
    });

    // Clean up subscription when the component unmounts
    return () => {
      channel.unsubscribe();
      //   ably.close();
    };
  }, [finalTranscript, qrkey,device_type]);

  const config = {
    placeholder: "Start typing or scanning QR...",
    height: 400,
  };
  const [value, setValue] = useState("");
  useEffect(() => {
    if (finalTranscript) {
      setValue(finalTranscript);
    }
  }, [finalTranscript]);

  const handleCreateProject = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData();
      formData.append("projectName", name);
      formData.append("description", value);
      formData.append("language", language);

      console.log(formData);
      const authToken = localStorage.getItem("authToken");
      const response = await axios.post(`${BASE_URL}/api/projects`, formData, {
        headers: {
          Token: `${authToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response.data.status === "success") {
        toast.success("Project created successfully!");
        setModalOpen(false);
        setTimeout(() => {
          navigate("/template");
        }, 3000);
      } else {
        toast.error("Failed to create project.");
      }
    } catch (error) {
      console.error("Error creating project:", error);
      toast.error("An error occurred while creating the project.");
    }
  };

  return (
    <div>
      <div
        className="min-h-screen flex flex-col"
        style={{
          backgroundImage: `url(${bgimg})`,
          backgroundSize: "cover",
        }}
      >
        <ToastContainer />
        <div>
          <Navbar />
          <div className="w-full lg:mt-8 xl:mt-20 mt-8 sm:mt-12 flex-grow">
            <div className="mx-4 sm:mx-2 lg:mx-16 px-4  xl:mx-20 shadow-md  md:mx-12 p-4  sm:p-8 lg:p-10 lg:pt-6 pb-md-10 pb-4 py-4 rounded-xl pt-md-10 pt-4 bg-white">
              {/* {qrkey && <p>QR Key: {qrkey}</p>} */}
              <div className="flex justify-between items-center">
                <div className="text-white mb-6 bg-green-500 p-3 py-1 rounded-full">
                  {device && (
                    <p className="fw-bold ">Connected With : &nbsp;{device}</p>
                  )}
                </div>
                <div className="flex justify-end mb-6">
                  {/* {id == "123" ? ( */}
                  <>
                    <button
                      onClick={() => setModalOpen(true)}
                      // onClick={handleCreateProject}
                      className="bg-[#008CD2] xs:hidden block text-white sm:px-4 sm:text-[12px] font-semibold text-[15px] inter_ff py-2 px-8 rounded-full"
                    >
                      Save as Template
                    </button>
                    <div className="text-center xs:block hidden">
                      <img
                        className="mx-auto"
                        onClick={() => setModalOpen(true)}
                        width={70}
                        src={save_icon}
                        alt="save_icon"
                      />
                      <p className="text-black text-[13px] font-bold">
                        Save as Template
                      </p>
                    </div>
                  </>
                  {/* ) : null} */}
                </div>
              </div>
              <div className="mt-2 text-center" id="content">
                <JoditEditor
                  className="w-full"
                  value={value}
                  onChange={(value) => setValue(value)}
                />
                {/* <div id="text-display">{finalTranscript}</div> */}
              </div>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg w-full max-w-md">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">Save Template</h2>
                <button onClick={() => setModalOpen(false)} className="text-xl">
                  &times;
                </button>
              </div>
              <form>
                <Modal.Body>
                  <form>
                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="name"
                      >
                        Project name
                      </label>
                      <input
                        className="border rounded-lg w-full py-2 px-3 text-gray-700"
                        id="name"
                        type="text"
                        placeholder="Your Name"
                        value={name}
                        onChange={(e) => setProjectName(e.target.value)}
                      />
                    </div>
                  </form>
                </Modal.Body>
                <Modal.Footer>
                  <div className="flex items-center justify-between">
                    <button
                      className="bg-[#008CD2] text-white font-bold py-2 px-4 rounded-3xl w-full"
                      type="submit"
                      onClick={handleCreateProject}
                    >
                      Save
                    </button>
                  </div>
                </Modal.Footer>
              </form>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default QrToText;
